<template>
    <CompTable ref="comp_table" :title="$core.getUrlParam('t')" :columns="columns" :table-api="tableApi">
        <template #operate>
            <Button type="info" @click="$refs.comp_table.export()">导出</Button>
        </template>
    </CompTable>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"

export default {
    components: {
        CompTable,
    },

    data() {
        return {
            tableApi: `/gateway/api/symanage/pc/columnTree/listColumnRemindsPeople?orgCode=${this.$core.getUrlParam("o")}&columnCode=${this.$core.getUrlParam("c")}&dateRange=4`,
            columns: [
                ...(this.$core.getUrlParam("type") === "column"
                    ? [
                          {
                              title: "栏目路径",
                              minWidth: 300,
                              align: "center",
                              key: "columnUrl",
                          },
                          {
                              title: "栏目编码",
                              minWidth: 150,
                              align: "center",
                              key: "columnCode",
                          },
                      ]
                    : []),
                {
                    title: "触发用户",
                    minWidth: 200,
                    align: "center",
                    key: "userName",
                },
                {
                    title: "手机号",
                    minWidth: 250,
                    align: "center",
                    key: "phone",
                },
                {
                    title: "触发时间",
                    minWidth: 200,
                    align: "center",
                    content: p => (p.gmtCreate ? this.$core.formatDate(new Date(p.gmtCreate), "yyyy/MM/dd hh:mm:ss") : "-"),
                    render: (h, params) => {
                        return h("p", params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy/MM/dd hh:mm:ss") : "-")
                    },
                },
            ],
        }
    },
}
</script>

<style></style>
